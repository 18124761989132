import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { IHeaderProps } from '../../global-interfaces';
import { HeaderTest } from '../HeaderTest/HeaderTest';
import s from './Header.scss';

export const Header = (props: IHeaderProps) => {
	useStyles(s);
	return <HeaderTest {...props} />;
};
